#footer {
    background: $default-color;
    padding: 60px 0 100px;

    .logo {
        margin-top: 10px;
        width: 228px;
        height: 35px;
        display: block;
        background-image: url('../images/footerLogo.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        margin-bottom: 20px;
    }

    .copyright-text {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #667E88;
        line-height: 36px;
    }

    .grid-menu {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                padding-bottom: 20px;

                a {
                    color: $white-color;
                    text-decoration: none;
                    font-size: 14px;
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    .phone {
        font-weight: 700;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0.5px;
        color: $white-color;
        text-decoration: none;
        padding-bottom: 10px;
        display: block;
    }

    .partner {
        padding-bottom: 15px;
        p {
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            color: $white-color;
            line-height: 18px;
            margin-bottom: 0;
        }

        a {
            font-weight: 700;
            font-style: normal;
            font-size: 14px;
            color: $white-color;
            text-decoration: none;
        }
    }
    .information {
        p {
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            color: $white-color;
            line-height: 18px;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .presentation {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: $white-color;
        display: inline-block;
        border: 1px solid $white-color;
        border-radius: $base-border-radius;
        padding: 15px 25px;
        text-decoration: none;
        background: transparent;
        transition: .2s;

        &:hover {
            transition: .2s;
            background: $primary-color;
            border-color: $primary-color;
        }
    }
}
