#our-advantages {
    @extend %section;

    background: $white-color;
    padding: 100px 0;

    .card {
        text-align: center;

        .image {
            padding-bottom: 25px;

            >div {
                width: 114px;
                height: 114px;
                display: block;
                margin: 0 auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .name {
            font-size: 21px;
            font-weight: 700;
            letter-spacing: 0.8px;
            padding-bottom: 25px;
        }

        .description {
            font-size: 16px;
            font-weight: 400;
            color: $default-color;
            white-space: pre-wrap;
        }
    }
}
