#catalog-item {
    min-height: 500px;
    padding: 25px 0 50px;
    background: rgba(242, 242, 242, 1);

    @extend %MediaPlanGridTitle;
    @extend %MediaPlanTotalBox;

    .loading {
        padding: 100px;
        text-align: center;
    }

    .description-grid {
        background-color: $white-color;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.0666666666666667);
        border-radius: 5px;
        padding: 25px 25px 25px;

        .navigation-grid {
            padding-bottom: 15px;
            display: inline-block;
            width: 100%;

            ul {
                margin: 0;
                padding: 0;
                // display: flex;

                li {
                    list-style-type: none;
                    display: flex;
                    padding-right: 50px;
                    padding-bottom: 10px;
                    float: left;

                    a {
                        width: 200px;
                        height: 50px;
                        border: 1px solid rgba(41, 67, 78, 1);
                        display: inline-block;
                        line-height: 50px;
                        text-align: center;
                        background: $white-color;
                        color: $default-color;
                        font-size: 16px;
                        border-radius: 5px;
                        text-decoration: none;
                        transition: .2s;

                        &.selected,
                        &:hover {
                            background-color: $primary-color;
                            color: $white-color;
                            border: 1px solid $primary-color;
                            transition: .2s;
                        }
                    }
                }

                li:last-child {
                    padding-right: 0;
                }
            }
        }

        .title {
            font-weight: 700;
            font-style: normal;
            font-size: 21px;
            color: $default-color;
            line-height: 30px;
        }

        .description {
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 23px;
            color: #333333;
        }

        .select-city {
            position: relative;
            padding: 25px 0;

            .select-section {
                width: 50%;
                overflow: hidden;
                position: relative;
                border-bottom: 1px solid $primary-color;

                .select-city-input {
                    width: calc(100% - 25px);
                    font-size: 30px;
                    border: 0;
                    color: $primary-color;
                    font-weight: 700;
                    padding: 0 0 10px;
                    outline: 0;
                }

                &::after {
                    content: "";
                    background-image: url("../images/selectCity.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: block;
                    width: 20px;
                    height: 100%;
                    position: absolute;
                    right: 5px;
                    top: 0;
                }
            }
            .options-section {
                width: 50%;
                position: relative;

                .select-city-listbox {
                    top: 5px;
                    width: 100%;
                    max-height: 300px;
                    margin: 0;
                    padding: 0;
                    z-index: 99999;
                    position: absolute;
                    list-style: none;
                    overflow: auto;
                    background-color: $white-color;
                    border-radius: 4px;
                    font-size: 16px;
                    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

                    li {
                        padding: 10px;
                    }

                    li[data-focus="true"] {
                        background-color: rgba(0, 0, 0, 0.06);
                        color: $default-color;
                        cursor: pointer;
                    }

                    li:active {
                        background-color: rgba(0, 0, 0, 0.06);
                        color: $default-color;
                    }
                }
            }
        }
    }

    .carousel {
        margin-top: 7px;

        .slider-slide,
        .slider-slide > img:focus {
            outline: 0;
        }

        .control-prev,
        .control-next {
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, .5);
            border: 0;
            outline: 0;
            display: block;
            border-radius: 50%;
            background-image: url("../images/arrow_table.svg");
            background-position: center 57%;
            background-repeat: no-repeat;
            cursor: pointer;
            transition: .2s;

            &:hover {
                transition: .2s;
                background: rgba(255, 255, 255, 1);
                background-image: url("../images/arrow_table_hover.svg");
                background-position: center 57%;
                background-repeat: no-repeat;
            }
        }

        .control-prev {
            margin-left: 10px;
            transform: rotate(90deg);
        }
        .control-next {
            margin-right: 10px;
            transform: rotate(-90deg);
        }

        .dotsContainer {
            .paging-item {
                margin-right: 5px;
                button {
                    outline: 0;
                    width: 10px;
                    height: 10px;
                    background: rgb(215, 215, 215)!important;
                    border-radius: 50%;
                    padding: 0;
                    opacity: 1!important;
                    transition: .2s;

                    svg {
                        display: none;
                    }
                }

                &.active {
                    button {
                        background: $primary-color!important;
                        transition: .2s;
                    }
                }
            }

            .paging-item:last-child {
                margin-right: 0;
            }
        }
    }
}
