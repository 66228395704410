#advertising-catalog {
    @extend %section;

    a {
        text-decoration: none;

        &.card {
            .image {
                img {
                    display: block;
                    margin: 0 auto 30px;
                }
            }

            .name {
                font-weight: 700;
                font-style: normal;
                font-size: 21px;
                letter-spacing: 0.8px;
                color: $default-color;
                text-align: center;
            }
        }
    }
}
